const Support = () => {
    return (<body>

      <div class="supportHead">
  
          <h1>Välkommen till Support!</h1>
          <p>Här finner du material och guider för användning av Skola77</p>
  
      </div>
  
      <div class="pdfs">
  
          <div class="material">
  
              <p>Snabbguide:</p>
  
              <a class="pdfLänk" href="/PDFs/Skola77 Snabbguide.pdf">Skola77:s snabbguide</a>
  
  
          </div>
  
          <div class="material">
  
           <p>Vilkor och integritetspolicy:</p>
  
           <a class="pdfLänk" href="/PDFs/Skola77 vilkor och integritetspolicy.pdf">Skola77:s policy</a>
  
  
       </div>
  
  
      </div>
      
  </body>);
  };
  
  export default Support;